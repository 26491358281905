import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
  standalone: true,
})
export class ReplacePipe implements PipeTransform {
  public transform(value: string, strToReplace: string, replacementStr: string): string {
    return value.replace(new RegExp(escapeStr(strToReplace), 'g'), replacementStr);
  }
}

function escapeStr(str) {
  return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}
